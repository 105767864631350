import React, { useState } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import '../../assets/styles.css'

const Layout = ({ children, ...props }) => {
  const [isBtnScrollActive, setIsBtnScrollActive] = useState('btnExit')

  if (typeof window !== `undefined`) window.addEventListener("scroll", scrollFunction)

  function scrollFunction() {
    if (window.pageYOffset > 200) {
      if(isBtnScrollActive !== "btnEntrance") {
        setIsBtnScrollActive('btnEntrance')
        setTimeout(function() {
          document.querySelector('#back-to-top-btn').style.display = "block"
        }, 250)
      }
    }
    else {
      if(isBtnScrollActive === "btnEntrance") {
        setIsBtnScrollActive('btnExit')
        setTimeout(function() {
          document.querySelector('#back-to-top-btn').style.display = "none"
        }, 250)
      }
    }
  }

  function smoothScrollBackToTop() {
    const targetPosition = 0
    const startPosition = window.pageYOffset
    const distance = targetPosition - startPosition
    const duration = 1000
    let start = null

    window.requestAnimationFrame(step)

    function step(timestamp) {
      if (!start) start = timestamp
      const progress = timestamp - start
      window.scrollTo(0, easeInOutCubic(progress, startPosition, distance, duration))
      if (progress < duration) window.requestAnimationFrame(step)
    }
  }

  function easeInOutCubic(t, b, c, d) {
    t /= d/2
    if (t < 1) return c/2*t*t*t + b
    t -= 2
    return c/2*(t*t*t + 2) + b
  }

  return (
    <div className='bg-white'>
      <Header />
      <div className={'container mx-auto min-h-screen '+ (props.ptop === false ? '' : ' pt-20')}>
        {children}
      </div>
      <div id='back-to-top-btn' className={isBtnScrollActive} onClick={() => smoothScrollBackToTop()}>
        <a rel='noopener noreferrer'>
          <svg className='w-10 fill-current text-gray-900 md:hover:text-gray-600 m-2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 337.085 337.086'>
            <path d='M168.54,0C75.619,0,0.012,75.609,0.012,168.543c0,92.93,75.606,168.543,168.528,168.543
              c92.933,0,168.534-75.613,168.534-168.543C337.074,75.609,261.473,0,168.54,0z M271.405,224.327
              c-2.407,2.402-5.561,3.615-8.713,3.615c-3.158,0-6.311-1.213-8.719-3.615L168.54,138.9l-85.424,85.427
              c-4.816,4.815-12.631,4.815-17.447,0c-4.819-4.815-4.819-12.622,0-17.438l94.152-94.141c4.822-4.815,12.613-4.815,17.426,0
              l94.152,94.141C276.221,211.705,276.221,219.512,271.405,224.327z'/>
          </svg>
        </a>
      </div>
      <Footer />
    </div>
  )
}

export default Layout
