import React, { useState } from 'react'
import { Link } from 'gatsby'
import styles from './style.module.css'
import './styles.css'

import requinteBasicoLogo from '../../assets/requinte-basico.svg'

const Header = () => {
  const [showMenu, setShowMenu] = useState('hidden ')
  const [toggleActive, setToggleActive] = useState('')
  const toggleMenu = () => {
    setShowMenu(oldValue => oldValue === 'hidden ' ?  '' : 'hidden ')
    toggleActive ? setToggleActive('') : setToggleActive('menu-toggle-active ')
  }

  return (
    <nav id='header' className={styles.header}>
      <div className='w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-6 py-3'>

        <div className='md:order-1 order-2'>
          <a className='flex items-center tracking-wide' href='/' title="Requinte Básico">
            <img className='fill-current' src={requinteBasicoLogo} width='86' viewBox='0 0 24 24' alt='Requinte Básico' title="Requinte Básico" />
          </a>
        </div>

        <div className='pb-2 md:hidden block'></div>

        <div className={showMenu+'md:flex md:items-left md:pl-16 md:w-auto w-full order-3'} id='menu'>
          <nav>
            <ul className='md:flex justify-between text-base text-gray-500 pt-4 md:pt-0'>
              <li key='empresa'><Link className='inline-block no-underline hover:text-white py-2 px-4 ' to={'/empresa'} onClick={toggleMenu}>Empresa</Link></li>
              <li key='produtos'><Link className='inline-block no-underline hover:text-white py-2 px-4 ' to={'/produtos'} onClick={toggleMenu}>Produtos</Link></li>
              <li key='novidades'><Link className='inline-block no-underline hover:text-white py-2 px-4 ' to={'/novidades'} onClick={toggleMenu}>Novidades</Link></li>
              <li key='promocoes'><Link className='inline-block no-underline hover:text-white py-2 px-4 ' to={'/promocoes'} onClick={toggleMenu}>Promoções</Link></li>
              <li key='contato'><Link className='inline-block no-underline hover:text-white py-2 px-4 ' to={'/contato'} onClick={toggleMenu}>Contato</Link></li>
            </ul>
          </nav>
        </div>

        <div className='order-2 flex items-center' id='nav-content'>
          <label httpfor='menu-toggle' className='pb-2 md:hidden block' onClick={toggleMenu}>
            <div className={toggleActive+'menu-toggle'}>
              <span></span>
            </div>
          </label>
          <input className='hidden' type='checkbox' id='menu-toggle' />

        </div>
      </div>
    </nav>
  )
}

export default Header
