import React from 'react'
import styles from './style.module.css'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

const Social = () => {
  return (
    <div className='social flex items-center order-1 justify-center md:order-2'>
      <a name='facebook' href='https://www.facebook.com/requintebasicobrasil/' rel='noopener noreferrer' target='_blank'>
        <svg className='w-6 fill-current text-gray-500 hover:text-gray-100 m-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.734 60.733">
        <path d="M57.378,0.001H3.352C1.502,0.001,0,1.5,0,3.353v54.026c0,1.853,1.502,3.354,3.352,3.354h29.086V37.214h-7.914v-9.167h7.914
          v-6.76c0-7.843,4.789-12.116,11.787-12.116c3.355,0,6.232,0.251,7.071,0.36v8.198l-4.854,0.002c-3.805,0-4.539,1.809-4.539,4.462
          v5.851h9.078l-1.187,9.166h-7.892v23.52h15.475c1.852,0,3.355-1.503,3.355-3.351V3.351C60.731,1.5,59.23,0.001,57.378,0.001z"/>
        </svg>
      </a>
      <a name='instagram' href='https://instagram.com/requintebasicobrasil?igshid=1uia5q2gwn7pd' rel='noopener noreferrer' target='_blank'>
        <svg className='w-6 fill-current text-gray-500 hover:text-gray-100 m-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160
            C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48
            h192c61.76,0,112,50.24,112,112V352z"/>
          <path d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336
            c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z"/>
          <circle cx="393.6" cy="118.4" r="17.056"/>
        </svg>
      </a>
      <a name='whatsapp' href='https://api.whatsapp.com/send?phone=5554997064410' rel='noopener noreferrer' target='_blank'>
        <svg className='w-6 fill-current text-gray-500 hover:text-gray-100 m-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M256.064,0h-0.128C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104l98.4-31.456
            C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z M405.024,361.504
            c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264C189.888,347.2,141.44,270.752,137.664,265.792
            c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304c6.176-6.304,16.384-9.184,26.176-9.184
            c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64c6.176,14.88,21.216,51.616,23.008,55.392
            c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744c-3.776,4.352-7.36,7.68-11.136,12.352
            c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616c28.576,25.44,51.744,33.568,60.032,37.024
            c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496c5.12-7.232,11.584-8.128,18.368-5.568
            c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736C411.2,329.152,411.2,344.032,405.024,361.504z"/>
        </svg>
      </a>
    </div>
  )
}

const Footer = () => {
  const { logo } = useStaticQuery(
    graphql`
      query {
        logo: allFile(filter: {relativePath: {eq: "loopz-logo-dark.png"}}) {
          edges {
            node {
              childImageSharp {
                fixed(width: 48) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `
  )
  return (
    <div id='footer' className={styles.footer}>
      <div className='flex items-center flex-col py-2'>

        <div className='visible pt-5'>
         <Social />
        </div>
        <div className='items-center text-center text-xs text-gray-400 pt-5'>
          <p>© Requinte Básico 2020.</p>
          <p className='py-2'>Desenvolvido por <a className='underline' href='https://www.loopz.com.br' target='_blank'>Loopz Company</a></p>
          <a href='https://www.loopz.com.br' target='_blank'><Img className='mx-auto' fixed={logo.edges[0].node.childImageSharp.fixed} alt='Loopz Logo' /></a>
        </div>

      </div>
    </div>
  )
}

export default Footer
